function makeSlideTextVisible(){
    $('.textbox').children().each(function (index, element) {
        var delayTime = index * 500;
        var $this = $(this)
        setTimeout(function(){
            $this.addClass('visible');
        }, delayTime);
    });
}

function checkLoginState() {
    FB.getLoginStatus(function(response) {
        statusChangeCallback(response);
    });
}

function statusChangeCallback(response){
    if (response.status === 'connected') {
        fbAPI();
    }
}

function fbAPI() {
    FB.api('/me',{ fields: 'name, email' }, function(response) {
        if( $("#szemelyes-adatok-form").length > 0 ){
            $("#szemelyes-adatok-form input[name='nev']").val(response.name);
            $("#szemelyes-adatok-form input[name='email']").val(response.email);
            $("#szemelyes-adatok-form input[name='fb_id']").val(response.id);
            $("#szemelyes-adatok-form input[name='jelszo']").val("").addClass("hide");
            $("#szemelyes-adatok-form input[name='jelszo_ujra']").val("").addClass("hide");
        }
        $.post("/frontend/rendelesek/tools", {fn: 'getFBData',name:response.name,email:response.email,user_id:response.id},function(ret){
            if(ret == "beleptet"){
                if( $("#szemelyes-adatok-form").length > 0 ){
                    location.reload();
                }else{
                    location.href = "/hazhozszallitas";
                }
            }
        })
    });
}

function revealTexts(){
    var triggerViewHeight = $( window ).height() * 0.75;
    $('.text_part').each(function (index, element) {
        var topOffset = $(window).scrollTop() + triggerViewHeight;
        var elementOffsetTop = $(this).offset().top;
        if( elementOffsetTop <= topOffset){
            $(this).addClass('visible');
        }
    });
}

function regformCheck(form_type){
    var error = false;
    var errorobj = [];
	
	if(form_type=="blokk"){
		var form_id = "feliratkozas-blokk-form";
	}else{
		var form_id = "feliratkozas-form";		
	}

    if ($("#"+form_id+" [name='hirlevel_nev']").val() == ""){
        error = true;
        errorobj.push({"str": lngMessages["A mező kitöltése kötelező!"], "obj": "#"+form_id+" [name='hirlevel_nev']"});
    }
	/*
    if ($("#"+form_id+" [name='hirlevel_keresztnev']").val() == ""){
        error = true;
        errorobj.push({"str": lngMessages["A mező kitöltése kötelező!"], "obj": "#"+form_id+" [name='hirlevel_keresztnev']"});
    }*/
	
    if ($("#"+form_id+" [name='hirlevel_email']").val() == "")
    {
        error = true;
        errorobj.push({"str": lngMessages["A mező kitöltése kötelező!"], "obj": "#"+form_id+" [name='hirlevel_email']"});
    } else {
        var emailReg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        if (!emailReg.test($("#"+form_id+" [name='hirlevel_email']").val()))
        {
            error = true;
            errorobj.push({"str": lngMessages["E-mail cím nem tűnik érvényesnek"], "obj": "#"+form_id+" [name='hirlevel_email']"});
        }
    }
    if ($("#"+form_id+" [name='hirlevel_elfogadom']:checked").val() != "1"){
        error = true;
        errorobj.push({"str": lngMessages["Kérjük, fogadja el adatvédelmi tájékoztatónkat!"], "obj": "#"+form_id+" [name='hirlevel_elfogadom']"});
    }
	
    if (!error){
        $.post("/hirlevel/feliratkozas", $("#"+form_id).serialize(),
            function(ret){
                if(ret.status=='ok'){
                    document.location.href=ret.url;
                }else{
                    $("div.errordiv").remove();
                    $(".errorredborder").removeClass("errorredborder");
                    $("#"+form_id+" .sor.errors").append("<div class='error errordiv'>"+lngMessages["Ezzel az e-mail címmel már feliratkoztak"]+"</div>");
                    $("#"+form_id+" [name='hirlevel_email']").addClass("errorredborder")
                }
            }, "json"
        )
        return false;
    } else {
        $("div.errordiv").remove();
        $(".errorredborder").removeClass("errorredborder");
        var listaba = true;
        $.each(errorobj, function(i, eobj){
            var err = "<div class='error errordiv'>"+eobj.str+"</div>";

            $(eobj.obj).addClass("errorredborder")

            if(listaba == false && eobj.str == lngMessages["A mező kitöltése kötelező!"]){
                return true;
            }
            if(eobj.str == lngMessages["A mező kitöltése kötelező!"]){
                listaba = false;
            }
            $("#"+form_id+" .sor.errors").append(err)
        })
        return true
    }

}


$(document).ready(function(){

    makeSlideTextVisible()

    $(".categories .toggle").click(function(){
        $(this).parent().parent().find(".wrapper").slideToggle();
        $(this).toggleClass("open");
    })

    $('.hamb').click(function (e) { 
        e.preventDefault();
        $(this).toggleClass('open');
        $('.menu-cont').toggleClass('open');
    });
/*    
    $('.cimlap-slider').on('init', function(event, slick){ 
        console.log('Slick init');
    });
    
    $('.cimlap-slider').on('afterChange', function(event, slick, currentSlide, nextSlide){
    });

    $(".cimlap-slider").slick({
        arrows: false,
        infinite: false
    });
*/
    var triggerViewHeight = $( window ).height() * 0.75;
    $(window).scroll(function(){
        var topOffset = $(window).scrollTop() + triggerViewHeight;
        var element = $('.text_part').each(function (index, element) {
            var elementOffsetTop = $(this).offset().top;
            if( elementOffsetTop <= topOffset){
                $(this).addClass('visible');
            }
        });
    });

    revealTexts();
	
    $(".newsletter").click(function(){
        $(".modal-cont").addClass("show")
    })
    $(".hirlevel-form .close").click(function(){
        $(".modal-cont").removeClass("show")
    })
    $("#feliratkozas-form .submit").click(function(){
        regformCheck("header")
    })
    $("#feliratkozas-blokk-form .submit").click(function(){
        regformCheck("blokk")
    })
	
});
